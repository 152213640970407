import React, { useContext } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Layout from '../components/layout'
import Logo from '../images/logo.svg'
import AuthContext from '../context/AuthContext'
import { hideSection } from '../utils'

const Home = () => {
  const { user } = useContext(AuthContext)
  const data = useStaticQuery(graphql`
    query {
      site {
          siteMetadata {
          description
          title
        }
      }
      allToC {
        edges {
          node {
            name
            description
            context
          }
        }
      }
    }
  `)

  const numOfTiles = data.allToC.edges?.length
  const tileWidth = 100 / numOfTiles

  const getTileContent = (name, description) => {
    return (
      <div className='home-page-tile'>
        <h2>{name}</h2>
        <p>{description}</p>
      </div>
    )
  }

  return (
    <Layout>
      <div className='home-page'>
        <div className='title'>
          <Logo />
          <div>
            <h1>{data.site.siteMetadata.title}</h1>
            <p>{data.site.siteMetadata.description}</p>
          </div>
        </div>
        <div className='sections'>
          {data.allToC.edges?.map(({ node }) => {
            const disableLink = hideSection(node.context, user?.permissions)
            if (disableLink) return (
              <span key={node.context} style={{ width: `${tileWidth}%` }} className='disabled'>
                {getTileContent(node.name, node.description)}
              </span>
            )
            return (
              <Link to={`/${node.context}`} key={node.context} style={{ width: `${tileWidth}%` }}>
                {getTileContent(node.name, node.description)}
              </Link>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Home